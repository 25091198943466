<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        styleClass="vgt-table table-custom-style striped"
        :line-numbers="false"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :columns="columns"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Back link Type  -->
          <template v-if="props?.column?.field === 'seller_format'">
            <div v-if="props?.row?.seller?.name">
              {{ props?.row?.seller?.name }}
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Status  -->
          <template v-if="props?.column?.field === 'status_text_format'">
              <div v-if="props?.row?.status_text == 'Paid'">
                <b-badge variant="light-success">
                  {{ props?.row?.status_text }}
                </b-badge>
              </div>

              <div v-else-if="props?.row?.status_text == 'Rejected'">
                <b-badge variant="light-danger">
                  {{ props?.row?.status_text }}
                </b-badge>
              </div>

              <div v-else-if="props?.row?.status_text == 'Awaiting Finance Approval'">
                <b-badge variant="light-warning">
                  {{ props?.row?.status_text }}
                </b-badge>
              </div>

              <div v-else-if="props?.row?.status_text == 'Pending'">
                <b-badge variant="light-primary">
                  {{ props?.row?.status_text }}
                </b-badge>
              </div>
          </template>

          <span v-if="props.column.field === 'action'">
            <template>
              <span @click="onShowDetails(props.row?.id)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="EyeIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="View"
                  size="16"
                />
              </span>
            </template>

            <template v-if="$permissionAbility(OUTREACH_INVOICE_EDIT, permissions)">
              <template v-if="props.row?.status_text !== 'Paid' && props.row?.status_text !== 'Rejected'">
                <span @click="onShow(props.row)">
                  <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    size="16"
                  />
                </span>
              </template>
            </template>

            <template v-if="$permissionAbility(OUTREACH_INVOICE_DELETE, permissions)">
              <span @click="onDelete(props.row.id)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="TrashIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="Delete"
                  size="16"
                />
              </span>
            </template>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-create-form"
      centered
      :title="modelType == 'editModel' ? 'Edit Seller' : 'Create Seller'"
      hide-footer
      @hidden="hiddenModal"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="createFormValidation">
        <b-form v-on:submit.prevent="validationForm">
          <b-row>
            <b-col md="6" lg="6" xs="12">
              <!-- invoice_number -->
              <b-form-group
                label="Invoice Number"
                label-for="invoice_number"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Invoice Number"
                  vid="invoice_number"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="invoice_number"
                    type="text"
                    v-model="invoiceNumberInput"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Invoice Number"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- invoice_date-->
              <b-form-group
                label="Invoice Date"
                label-for="invoice_date"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="invoice_date"
                  vid="invoice_date"
                  rules="required"
                >
                  <b-form-datepicker
                    id="invoice_date"
                    v-model="invoiceDateInput"
                    class="form-control custom-font"
                    :state="errors.length > 0 ? false : null"
                    locale="en-US"
                    today-button
                    close-button
                    reset-button
                    placeholder="Invoice Date"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- payment_method -->
              <b-form-group
                label="Payment Method"
                label-for="payment_method"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="payment_method"
                  vid="payment_method"
                  rules="required"
                >
                  <v-select
                    id="payment_method"
                    v-model="selectPaymentId"
                    :options="filteredPaymentOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- payment_date-->
              <b-form-group label="Payment Date" label-for="payment_date">
                <validation-provider
                  #default="{ errors }"
                  name="payment_date"
                  vid="payment_date"
                >
                  <b-form-datepicker
                    id="payment_date"
                    v-model="paymentDateInput"
                    class="form-control custom-font"
                    :state="errors.length > 0 ? false : null"
                    locale="en-US"
                    today-button
                    close-button
                    reset-button
                    placeholder="Payment Date"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- status -->
              <b-form-group
                label="Status"
                label-for="status"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  vid="status"
                  rules="required"
                >
                  <v-select
                    id="status"
                    v-model="selectInvoiceStatusId"
                    :options="filteredInvoiceStatusOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- approver_id -->
              <b-form-group
                label="Approver"
                label-for="approver_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Approver"
                  vid="approver_id"
                >
                  <v-select
                    id="approver_id"
                    v-model="selectApproverId"
                    :options="filteredApproverOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="Choose Here"
                  >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12" lg="12" xs="12">
              <!-- payment_details -->
              <b-form-group label="Payment Details" label-for="payment_details">
                <validation-provider
                  #default="{ errors }"
                  name="Payment Details"
                  vid="payment_details"
                >
                  <b-form-textarea
                      id="payment_details"
                      v-model="paymentDetailsInput"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Payment Details"
                      rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12" lg="12" xs="12">
              <!-- remarks -->
              <b-form-group label="Remarks" label-for="remarks">
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
                >
                  
                  <b-form-textarea
                      id="remarks"
                      v-model="remarksInput"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Remarks"
                      rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- loading button -->
          <template v-if="isSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
  VBTooltip,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
import UserSelect from '@/layouts/components/UserSelect.vue'
import {
  OUTREACH_INVOICE_ACCESS,
  OUTREACH_INVOICE_CREATE,
  OUTREACH_INVOICE_EDIT,
  OUTREACH_INVOICE_DELETE,
  OUTREACH_INVOICE_SHOW,
} from "@/helpers/permissionsConstant";

export default {
  name: "OutreachInvoiceView",
  components: {
    UserAssignPolicyForm,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BFormDatepicker,
    BFormTextarea,
    UserSelect,

  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // Permissions
      OUTREACH_INVOICE_ACCESS,
      OUTREACH_INVOICE_CREATE,
      OUTREACH_INVOICE_EDIT,
      OUTREACH_INVOICE_DELETE,
      OUTREACH_INVOICE_SHOW,
      // invoice
      invoiceNumberInput: "",
      invoiceDateInput: "",
      selectSellerId: "",
      selectPaymentId: "",
      filteredPaymentOptions: [
        { id: 0, name: "Not Set" },
        { id: 1, name: "Bank Transfer" },
        { id: 2, name: "PayPal" },
        { id: 3, name: "Credit Card" },
        { id: 4, name: "Bkash" },
        { id: 5, name: "Nagadh" },
        { id: 6, name: "Rocket" },
      ],
      paymentDateInput: "",
      selectInvoiceStatusId: "",
      filteredInvoiceStatusOptions: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Awaiting Finance Approval" },
        { id: 2, name: "Paid" },
        { id: 3, name: "Rejected" },
      ],
      paymentDetailsInput: "",
      remarksInput: "",
      backLinkId: "",

      modelType: "",
      name: "",

      outreachInvoiceId: "",
      nicheName: "",

      selectApproverId: "",
        filteredApproverOptions: [],

      //table
      pageLength: 10,
      columns: [
        {
          label: "Invoice Number",
          field: "invoice_number",
          sortable: false,
        },
        {
          label: "Invoice Date",
          field: "invoice_date",
          formatFn: this.formatDateTime,
          sortable: false,
        },
        {
          label: "Seller",
          field: "seller_format",
          sortable: false,
        },
        {
          label: "Payment Method",
          field: "payment_method_text",
          sortable: false,
        },
        {
          label: "Payment Date",
          field: "payment_date",
          formatFn: this.formatDateTime,
          sortable: false,
        },
        {
          label: "Status",
          field: "status_text_format",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      this.loadItems();
      await this.getAllApprovers();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    
    showModal() {
      this.$bvModal.show("modal-create-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-create-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.outreachInvoiceId = "";

      this.nicheName = "";
      this.invoiceNumberInput = "";
      this.invoiceDateInput = "";
      this.selectSellerId = "";
      this.selectPaymentId = "";
      this.paymentDateInput = "";
      this.selectInvoiceStatusId = "";
      this.paymentDetailsInput = "";
      this.remarksInput = "";
      this.backLinkId = "";
      this.selectApproverId = "";
    },

    onShow(value) {
      this.modelType = "editModel";
      this.outreachInvoiceId = value.id;

      this.invoiceNumberInput = value.invoice_number;
      this.invoiceDateInput = value.invoice_date;
      this.selectSellerId = value.seller_id;
      this.selectPaymentId = value.payment_method;
      this.paymentDateInput = value.payment_date;
      this.selectInvoiceStatusId = value.status;
      this.paymentDetailsInput = value.payment_details;
      this.remarksInput = value.remarks;
      this.backLinkId = value.backlink_id;
      this.selectApproverId = value.approver_id;

      this.showModal();
    },

    onShowDetails(id) {
      this.$router.push({
        name: "outreach-invoice-details",
        params: { id },
      });
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    async getAllApprovers() {
      try {
        const response = await this.$api.get("api/users/active-all");

        this.filteredApproverOptions = (response?.data?.data || []).map(
          item => ({
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }),
        );

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/outreach-invoices/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Outreach Invoice Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    async getOutreachNicheItems(params) {
      return await this.$api.get("api/outreach-invoices", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    async loadItems() {
      try {
        const outreachNiche = await this.getOutreachNicheItems({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = outreachNiche?.data?.data;
        const meta = outreachNiche?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;

        console.log(this.rows);
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.createFormValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.createFormValidation.reset();
            if (this.modelType == "editModel") {
              this.isSubmitLoading = true;
              await this.$api.put(
                `/api/outreach-invoices/${this.outreachInvoiceId}`,
                {
                  invoice_number: this.invoiceNumberInput,
                  invoice_date: this.invoiceDateInput,
                  seller_id: this.selectSellerId,
                  payment_method: this.selectPaymentId,
                  payment_date: this.paymentDateInput,
                  status: this.selectInvoiceStatusId,
                  payment_details: this.paymentDetailsInput,
                  remarks: this.remarksInput,
                  backlink_id: this.backLinkId,
                  approver_id: this.selectApproverId,
                }
              );
              this.isSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Outreach Seller Successfully Updated",
                },
              });
            }
          } catch (error) {
            this.isSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.createFormValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>

